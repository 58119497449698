<template>
    <div class="relative max-h-[600px] shadow p-5 rounded-lg bg-white dark:bg-gray-750">
        <template v-if="loading">
            <div class="absolute top-0 left-0 w-full h-full z-40 backdrop-blur-sm flex items-center justify-center">
                <Loading />
            </div>
        </template>

        <div class="rounded-lg overflow-hidden">
            <div class="">
                <div class="flex items-center justify-between">
                    <h2 class="font-semibold text-2xl dark:text-gray-300">Tuvākie svētki</h2>
                </div>

                <template v-if="data">
                    <div class="lg:grid lg:grid-cols-12 lg:gap-x-16">
                        <ol class="mt-4 divide-y divide-gray-100 dark:divide-gray-600 text-sm leading-6 lg:col-span-7 xl:col-span-8">
                            <template v-for="(item, index) in data" :key="index">
                                <li class="relative flex space-x-6 py-2 xl:static">
                                    <div class="flex-auto">

                                        <dl class="mt-1 text-gray-500 dark:text-gray-300">
                                            <div class="flex items-start space-x-3">
                                                <dd :class="item.today || item.tomorrow ? 'text-main4' : ''">
                                                    <time datetime="{{item.full_date}}">{{getDate(item.full_date, item)}}</time>
                                                </dd>
                                            </div>
                                        </dl>
                                        <h3 class="pr-10 font-semibold text-gray-900 xl:pr-0 dark:text-gray-200">
                                            {{item.name}}
                                            <template v-if="item.company">
                                                <span class="font-normal">({{item.company}})</span>
                                            </template>
                                        </h3>

                                        <dl class="mt-1 text-gray-500 dark:text-gray-300">
                                            <div class="mt-2 flex items-start xl:mt-0">
                                                <dd>{{item.event}}</dd>
                                            </div>
                                        </dl>
                                    </div>
                                </li>
                            </template>
                        </ol>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios"
var baseUrl = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_URL_PRODUCTION : process.env.VUE_APP_BASE_URL;
import moment from "moment"

import { defineAsyncComponent } from 'vue'
const Loading = defineAsyncComponent(() =>
  import('@/components/Components/Loading'))

export default {
    name: "CelebrationsWidget",
    components: {
        Loading,
    },
    props: {
        component: {
            type: Object
        }
    },
    data() {
        return {
            data: null,
            loading: false,
        }
    },
    created() {
        this.loading = true
        axios
            .get(baseUrl + "/api/dashboard_components/" + this.component.id)
            .then((response) => {
                this.data = response.data
                this.loading = false
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    this.$store.dispatch("destroyToken");
                }
                this.loading = false
            });
    },
    methods: {
        getDate(date, item) {
            const newDate = moment(date, 'D.MM.YYYY').format('LL')
            if (item.today) {
                return "Šodien, " + newDate
            } else if (item.tomorrow) {
                return "Rītdien, " + newDate
            }
            return newDate
        }
    }
}
</script>

<style>

</style>